<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="4" style="text-align:right;">
        <v-form v-model="valid" @submit.prevent="processLogin()">
          <v-text-field
            autocomplete
            label="Email"
            v-model="email"
            type="email"
            :rules="rules.email"
            required
            :disabled="loggingIn"
          ></v-text-field>
          <v-text-field
            autocomplete
            label="Senha"
            v-model="password"
            type="password"
            :rules="rules.password"
            required
            :disabled="loggingIn"
          ></v-text-field>
          <v-btn
            :style="!valid ? 'cursor: not-allowed;' : 'cursor: pointer;'"
            :disabled="!valid || loggingIn"
            type="input"
            color="primary"
            >Login</v-btn
          >
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'auth',
  components: {},
  data: () => ({
    valid: null,
    loggingIn: false,
    dialogs: {
      Login: true
    },
    rules: {
      email: [
        v => !!v || 'Digite seu e-mail',
        v =>
          /.+@.+/.test(v) || 'O endereço de e-mail deve ter um formato válido'
      ],
      password: [v => !!v || 'Digite sua senha']
    },
    email: null,
    password: null
  }),
  methods: {
    processLogin() {
      var vm = this
      if (vm.$lConfig.DEBUG) {
        vm.email = null
        vm.password = null
        vm.$snotify.success(`Conexão efetuada com sucesso`)
        vm.$router.push('/main')
      } else {
        vm.loggingIn = true
        vm.login()
          .then(result => {
            if (result || result.status === 200) {
              vm.email = null
              vm.password = null
              vm.$snotify.success(`Conexão efetuada com sucesso`)
              vm.$router.push('/main')
            } else {
              vm.$snotify.error(`Falha no login: ${result.statusText}`)
            }
            vm.loggingIn = false
          })
          .catch(error => {
            if (error && error.response && error.response.status === 400) {
              vm.$snotify.error('Email ou senha inválida')
            } else {
              vm.$snotify.error(error.message)
            }
            vm.loggingIn = false
          })
      }
    },
    login() {
      var vm = this
      return new Promise((resolve, reject) => {
        if (vm.valid) {
          vm.$API
            .Login(vm.email, vm.password)
            .then(result => {
              resolve(result)
            })
            .catch(error => {
              reject(error)
            })
        } else {
          reject(
            new Error(
              'Ops... Primeiro você deve preencher seu e-mail e sua senha'
            )
          )
        }
      })
    }
  }
}
</script>
